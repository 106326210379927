import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SelectOption } from 'src/app/settings/settings.model';
import { clone, isDefined } from 'src/app/shared/common';
import {
    processWhen,
    whenSelectOptions,
} from 'src/app/shared/models/alert-action';

import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './alert-text-dialog.component.html',
    standalone: false,
})
export class AlertTextDialog implements OnInit {
    phoneNumbers: string[] = [];
    whenOptions: SelectOption[] = whenSelectOptions;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    when = new FormControl(null);
    form = new FormGroup({
        text: new FormControl(''),
        textMessage: new FormControl('', Validators.required),
        onEnter: new FormControl(null),
        onExit: new FormControl(null),
    });

    get isGeoFence() {
        return this.data?.isGeoFence;
    }

    get docsUrl() {
        return environment.docsUrl + '/variable-interpolation';
    }

    constructor(
        public dialogRef: MatDialogRef<AlertTextDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        this.form.patchValue(this.data?.alert);
        this.phoneNumbers = clone(this.data?.alert?.phoneNumbers) ?? [];
        this.when.patchValue(this.data?.when);
        // Set up international phone number validation
        if (this.isGeoFence) {
            this.when.setValidators(Validators.required);
        }
    }

    confirm() {
        const when = processWhen(this.when?.value);
        this.form.patchValue({ text: null });
        this.dialogRef.close({
            ...this.form.value,
            phoneNumbers: this.phoneNumbers,
            ...when,
        });
    }

    add(event: MatChipInputEvent): void {
        const input = event.chipInput.inputElement;
        if (input.value != '') {
            const phoneNumber = this.validatePhoneNumber(input.value);
            if (isDefined(phoneNumber)) {
                this.phoneNumbers.push(phoneNumber);
                input.value = '';
                // TODO: figure out what this line should be.
                // this.form.controls['text'].markAsPristine;
            }
        }
    }

    remove(text: string): void {
        const index = this.phoneNumbers.indexOf(text);
        if (index >= 0) {
            this.phoneNumbers.splice(index, 1);
        }
    }

    validatePhoneNumber(phoneNumber: string): string {
        // International EPP Format Regex
        const regex = /^\+?[0-9]{1,3}.[0-9]{4,14}(?:x.+)?$/;
        if (regex.test(phoneNumber)) {
            return phoneNumber.replace(/\s+/g, '');
        } else {
            this.form.controls['text'].setErrors({ phoneFormat: true });
        }
    }
}
