import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    ChartCanMessage,
    ChartCanSignal,
} from 'src/app/chart/models/signals/log';
import { SelectGroup, SelectOption } from 'src/app/settings/settings.model';

@Component({
    templateUrl: './can-signal-selection-dialog.component.html',
    standalone: false,
})
export class CanSignalSelectionDialog implements OnInit {
    public messageControl = new FormControl<number>(null);
    public messages: ChartCanMessage[];
    public selectGroup: SelectGroup[];
    public signals: ChartCanSignal[] = [];
    public selectedSignals: ChartCanSignal[] = [];
    public messageOptions: SelectOption[] = [];

    constructor(
        public dialogRef: MatDialogRef<CanSignalSelectionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: { messages: ChartCanMessage[] },
    ) {
        this.messages = data.messages ?? [];
        this.selectGroup = [
            ...new Set(data.messages.map((item) => item.modelId)),
        ].map((id) => {
            return {
                id: id.toString(),
                name: data.messages.find((item) => item.modelId === id)
                    .modelName,
            };
        });
        this.messageOptions = data.messages.map((element) => ({
            value: element.id,
            name: element.descriptor,
            groupId: element.modelId.toString(),
        }));
    }

    ngOnInit() {
        this.messageControl.valueChanges.subscribe((id) => {
            const message = this.messages.find((m) => m.id == id);
            if (!message) {
                return;
            }
            this.signals = message.signals;
        });
        if (this.messages.length) {
            this.messageControl.setValue(this.messages[0].id);
        }
    }

    /**
     * Select all the signals for the currently selected message
     */
    selectAllSignals() {
        for (const signal of this.signals) {
            const foundIndex = this.findSelectedSignalIndex(signal);
            if (foundIndex < 0) {
                // Add the signal
                this.selectedSignals.push(signal);
            }
        }
    }

    /**
     * Find the index of the signal in the selectedSignals array.
     * Return -1 if the signal is not selected.
     */
    findSelectedSignalIndex(signal: ChartCanSignal): number {
        for (let i = 0; i < this.selectedSignals.length; i++) {
            const selectedSignal = this.selectedSignals[i];
            if (selectedSignal.id == signal.id) {
                return i;
            }
        }
        return -1;
    }

    /**
     * If the signal is not selected, select it.
     * If the signal is selected, deselect it.
     */
    toggleSignal(signal: ChartCanSignal) {
        const foundIndex = this.findSelectedSignalIndex(signal);
        if (foundIndex >= 0) {
            // Remove the signal
            this.selectedSignals.splice(foundIndex, 1);
        } else {
            // Add the signal
            this.selectedSignals.push(signal);
        }
    }

    /**
     * Check if the signal is selected.
     */
    isSignalSelected(v: ChartCanSignal): boolean {
        return this.findSelectedSignalIndex(v) >= 0;
    }

    handleSave() {
        this.dialogRef.close(this.selectedSignals);
    }
}
