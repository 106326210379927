import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';

@Component({
    templateUrl: './info-dialog.component.html',
    standalone: false,
})
export class InfoDialog {
    private clipboard = inject(ClipboardService);
    private snackBar = inject(SnackBarService);
    constructor(
        public dialogRef: MatDialogRef<InfoDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: { title: string; content?: string; jsonContent?: object },
    ) {
        if (data.content != undefined && data.jsonContent != undefined) {
            throw Error(
                'You must only specify content or jsonContent, not both!',
            );
        }
    }

    copyData() {
        try {
            let stringToCopy;
            if (this.data.content != undefined) {
                stringToCopy = this.data.content;
            } else {
                stringToCopy = JSON.stringify(this.data.jsonContent, null, 2);
            }
            this.clipboard.copy(stringToCopy);
            this.snackBar.open('common.copy.success');
            this.dialogRef.close();
        } catch (error) {
            this.snackBar.open('common.copy.error');
        }
    }
}
