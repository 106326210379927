import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';
import { DatasourceFactory } from 'src/app/dashboard/models/datasource-factory';
import { DatasourceType } from 'src/app/dashboard/models/datasource-type';
import { Pane } from 'src/app/dashboard/models/pane';
import { GaugeWidget } from 'src/app/dashboard/models/widgets/gauge';
import { IndicatorWidget } from 'src/app/dashboard/models/widgets/indicator';
import { MapWidget } from 'src/app/dashboard/models/widgets/map';
import { TextWidget } from 'src/app/dashboard/models/widgets/text';
import { SelectOption } from 'src/app/settings/settings.model';
import { DashboardContent } from 'src/app/shared/models/dashboard';

enum DashboardTemplate {
    Default,
    Empty,
}

@Component({
    selector: 'app-create-dashboard-dialog',
    templateUrl: './create-dashboard-dialog.component.html',
    standalone: false,
})
export class CreateDashboardDialog {
    form = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        template: new FormControl(DashboardTemplate.Default),
    });
    templates: SelectOption[] = [
        {
            value: DashboardTemplate.Default,
            name: 'dashboard.template.default',
        },
        { value: DashboardTemplate.Empty, name: 'dashboard.template.empty' },
    ];

    get content(): DashboardContent {
        switch (this.form.get('template').value) {
            case DashboardTemplate.Default:
                return defaultDashboardContent();
            case DashboardTemplate.Empty:
                return {
                    version: 1,
                    numColumns: 4,
                    datasources: [],
                    panes: [],
                    panePositions: new Map(),
                    paneWidths: new Map(),
                };
        }
    }

    constructor(public dialogRef: MatDialogRef<CreateDashboardDialog>) {
        this.form.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
            const rawName = this.form.get('name').value;
            if (/^\s*$/.test(rawName)) {
                this.form.controls['name'].setValue('');
            }
        });
    }

    async handleSave() {
        this.dialogRef.close({
            name: this.form.get('name').value.trim(),
            content: this.content,
        });
    }
}

function defaultDashboardContent(): DashboardContent {
    const panes = [
        new Pane({
            title: 'Date & Time',
            widgets: [
                new TextWidget({
                    animate: true,
                    size: 'regular',
                    title: "Today's Date",
                    value: 'datasources["Time"]["date_string_value"]',
                }).serialized,
                new TextWidget({
                    animate: true,
                    size: 'regular',
                    title: 'Time',
                    value: 'datasources["Time"]["time_string_value"]',
                }).serialized,
            ],
        }),
        new Pane({
            title: 'Map',
            widgets: [new MapWidget({ lat: '', lon: '' }).serialized],
        }),
        new Pane({
            title: 'Weather',
            widgets: [
                new IndicatorWidget({
                    offText: 'Better stay in today. ',
                    onText: "It's beautiful!",
                    title: 'How is the weather?',
                    value: 'datasources["Weather"]["current_temp"]>50&&datasources["Weather"]["current_temp"]<85',
                }).serialized,
                new GaugeWidget({
                    max_value: '110',
                    min_value: '-20',
                    reverse: false,
                    reverseColor: true,
                    title: 'Temperature',
                    units: 'degrees F',
                    value: 'datasources["Weather"]["current_temp"]',
                }).serialized,
                new TextWidget({
                    animate: true,
                    size: 'regular',
                    title: 'Current Conditions',
                    value: 'datasources["Weather"]["conditions"]',
                }).serialized,
            ],
        }),
    ];
    return {
        version: 1,
        numColumns: 4,
        datasources: [
            DatasourceType.DEVICE,
            DatasourceType.TIME,
            DatasourceType.WEATHER,
        ].map((type) => DatasourceFactory.defaultDatasource(type).build()),
        panes,
        panePositions: new Map([
            [panes[0].id, { x: 0, y: 0 }],
            [panes[1].id, { x: 1, y: 0 }],
            [panes[2].id, { x: 3, y: 0 }],
        ]),
        paneWidths: new Map([
            [panes[0].id, 1],
            [panes[1].id, 2],
            [panes[2].id, 1],
        ]),
    };
}
