import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { NewPasswordDialog } from '../dialogs/new-password-dialog/new-password-dialog.component';
import { defaultPrimaryColor } from '../services/constants';
import { DialogService } from '../services/dialog/dialog.service';
import { PlatformConfigurationService } from '../services/platform-configuration.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: false,
})
export class ResetPasswordComponent implements OnInit {
    private dialog = inject(DialogService);
    private route = inject(ActivatedRoute);
    private materialCssVarsService = inject(MaterialCssVarsService);
    platformConfigurationService = inject(PlatformConfigurationService);

    ngOnInit() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        this.materialCssVarsService.setPrimaryColor(defaultPrimaryColor);
        this.route.queryParamMap.subscribe(async (params) => {
            await this.dialog.open(NewPasswordDialog, {
                data: {
                    dialogType: 'reset',
                    token: params.get('token'),
                },
                disableClose: true,
            });
        });
    }
}
