import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavSection, allSections } from './data';
import { hasRole } from 'src/app/shared/auth-utils';
import { RouteMetadataService } from 'src/app/services/route-metadata/route-metadata.service';

@Component({
    selector: 'app-nav-menu',
    templateUrl: 'nav-menu.component.html',
    standalone: false,
})
export class NavMenuComponent implements OnInit {
    public routeMetadataService = inject(RouteMetadataService);

    @Input() canInteract = true;
    @Output() hide = new EventEmitter();

    sections: NavSection[] = [];
    notificationCount;

    constructor(
        protected auth: AuthService,
        private api: ApiService,
    ) {}

    ngOnInit() {
        this.sections = allSections
            .map((section) => ({
                ...section,
                paths: section.paths.filter((path) =>
                    hasRole(this.routeMetadataService.getHasRole(path)),
                ),
            }))
            .filter((section) => section.paths.length > 0);

        this.api.announcements
            .listen({ unread: true, isActive: true })
            .subscribe((unread) => {
                this.notificationCount =
                    unread.length > 0 ? unread.length : undefined;
            });
    }
}
