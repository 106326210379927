import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
    withJsonpSupport,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import {
    BrowserAnimationsModule,
    NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie-service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppComponent } from '../app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { BadRouteComponent } from './bad-route/bad-route.component';
import { CommonModule } from './common/common.module';
import { DomService } from './dashboard/service/dom.service';
import { DialogModule } from './dialogs/dialog.module';
import { NavBarModule } from './nav-bar/nav-bar.module';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RootComponent } from './root/root.component';
import { AppMaterialModule } from './services/app-material/app-material.module';
import { AuthService } from './services/auth/auth.service';
import { CustomDateAdapter } from './services/custom-date-adapter';
import { DataTableLanguageService } from './services/data-table-language/data-table-language.service';
import { DateTimeService } from './services/date-time.service';
import { DialogService } from './services/dialog/dialog.service';
import { MultiTranslateHttpLoader } from './services/multi-translate-http-loader';
import { SearchService } from './services/search/search.service';
import { ShortcutsService } from './services/shortcuts/shortcuts.service';
import { SnackBarService } from './services/snack-bar/snack-bar.service';
import { TokenInterceptor } from './services/token.interceptor';
import { SettingsModule } from './settings/settings.module';
import { PlatformConfigurationService } from './services/platform-configuration.service';

@NgModule({
    declarations: [
        AppComponent,
        RootComponent,
        ResetPasswordComponent,
        ResetPasswordSuccessComponent,
        BadRouteComponent,
    ],
    exports: [],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
        NoopAnimationsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatSelectModule,
        MatFormFieldModule,
        MatNativeDateModule,
        NgxMatSelectSearchModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                // Custom translate loader to manage
                // multiple translation directories
                useFactory: (http: HttpClient): MultiTranslateHttpLoader => {
                    return new MultiTranslateHttpLoader(http, {
                        resources: [
                            {
                                prefix: '/assets/i18n/common/',
                                suffix: '.json',
                            },
                            {
                                prefix: '/assets/i18n/widgets/',
                                suffix: '.json',
                            },
                            {
                                prefix: '/assets/i18n/nav/',
                                suffix: '.json',
                            },
                        ],
                    });
                },
                deps: [HttpClient],
            },
        }),
        HammerModule,
        ScrollingModule,
        ClipboardModule,
        DragDropModule,
        GoogleMapsModule,
        CommonModule,
        SettingsModule,
        DialogModule,
        NavBarModule,
    ],
    providers: [
        AuthService,
        CookieService,
        SearchService,
        DataTableLanguageService,
        CustomDateAdapter,
        DatePipe,
        MaterialCssVarsService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: DateAdapter, useClass: CustomDateAdapter },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 4000, horizontalPosition: 'left' },
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                width: '450px',
                hasBackdrop: true,
                autoFocus: true,
            },
        },
        DomService,
        DateTimeService,
        SnackBarService,
        DialogService,
        ShortcutsService,
        PlatformConfigurationService,
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    ],
})
export class AppModule {}
