import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../services/user/settings.service';
import { ApiService } from '../services/api/api.service';

export enum BadRouteKind {
    Unknown = 'unknown',
    NotFound = 'not-found',
    NotAuth = 'not-auth',
}

@Component({
    selector: 'app-bad-route',
    templateUrl: './bad-route.component.html',
    standalone: false,
})
export class BadRouteComponent implements OnInit {
    kind: BadRouteKind = BadRouteKind.Unknown;
    isDarkMode = false;

    get text() {
        return this.kind == BadRouteKind.NotAuth
            ? 'bad_route.not_auth.text'
            : 'bad_route.not_found.text';
    }

    get title() {
        return this.kind == BadRouteKind.NotAuth
            ? 'bad_route.not_auth.title'
            : 'bad_route.not_found.title';
    }

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private settings: SettingsService,
        private api: ApiService,
    ) {}

    async ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.kind = data.kind;
        });
        if (!this.settings.isLoaded) {
            this.settings.init(await this.api.settings.all());
        }
        this.settings.values$.subscribe(() => {
            this.isDarkMode = this.settings.get('extras.dark-mode');
        });
    }

    goHome() {
        this.router.navigate(['']);
    }
}
