import { AfterViewInit, Component, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
import { MonacoEditorService } from './services/monaco-editor/monaco-editor-service';
import { log } from './shared/log';
import { PlatformConfigurationService } from './services/platform-configuration.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false,
})
export class AppComponent implements AfterViewInit {
    titleService = inject(Title);
    platformConfigurationService = inject(PlatformConfigurationService);

    title = 'app';
    apiUrl = environment.apiUrl;

    constructor(
        private translate: TranslateService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private monacoEditorService: MonacoEditorService,
        private appService: AppService,
        private materialCssVarsService: MaterialCssVarsService,
    ) {
        log.start();
        log.tag('AppComponent').info('create');
        this.materialCssVarsService.setPrimaryColor('#ffffff'); // white
        this.materialCssVarsService.setAccentColor('#a3a3a3'); // grey-400
        this.materialCssVarsService.setWarnColor('#ef4444'); // red-500

        let language = localStorage.getItem('language');
        if (!language) {
            language = environment.language;
            if (!language) {
                language = 'en';
            }
        }
        this.translate.setDefaultLang(language);

        this.setIcons();

        // Preload the Monaco Editor
        this.monacoEditorService.load();
    }

    ngAfterViewInit() {
        this.appService.startHotKeys();
        this.updateFavicon();
        this.titleService.setTitle(this.platformConfigurationService.name);
    }

    private updateFavicon(): void {
        const link: HTMLLinkElement | null =
            document.querySelector("link[rel~='icon']");
        if (link) {
            link.href = this.platformConfigurationService.favicon;
        } else {
            const newLink: HTMLLinkElement = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = this.platformConfigurationService.favicon;
            document.head.appendChild(newLink);
        }
    }

    setIcons() {
        this.addSvg('icon-delete', '../assets/img/icons/delete.svg');
        this.addSvg('icon-settings', '../assets/img/icons/settings.svg');
        this.addSvg('icon-close', '../assets/img/icons/close.svg');
        this.addSvg(
            'icon-close-circle',
            '../assets/img/icons/close-circle.svg',
        );
        this.addSvg('icon-sync', '../assets/img/icons/sync.svg');
        this.addSvg('icon-download', '../assets/img/icons/download.svg');
        this.addSvg('icon-arrow', '../assets/img/icons/arrow.svg');
        this.addSvg('icon-check', '../assets/img/icons/check.svg');
        this.addSvg('icon-dog', '../assets/img/icons/dog.svg');
        this.addSvg('icon-gas', '../assets/img/icons/gas.svg');
        this.addSvg('icon-speedometer', '../assets/img/icons/speedometer.svg');
        this.addSvg('icon-warning', '../assets/img/icons/warning.svg');
        this.addSvg('icon-wifi', '../assets/img/icons/wifi.svg');
        this.addSvg('icon-reset', '../assets/img/icons/reset.svg');
        this.addSvg('icon-exit', '../assets/img/icons/exit.svg');
        this.addSvg(
            'icon-temperature',
            '../assets/img/widgets/filter/temperature.svg',
        );
        this.addSvg('icon-trash', '../assets/img/widgets/filter/trash.svg');
        this.addSvg('icon-minimize', '../assets/img/icons/minimize.svg');
        this.addSvg('icon-maximize', '../assets/img/icons/maximize.svg');
        this.addSvg('icon-subtract', '../assets/img/icons/subtract.svg');
    }

    private addSvg(name: string, path: string) {
        this.matIconRegistry.addSvgIcon(
            name,
            this.domSanitizer.bypassSecurityTrustResourceUrl(path),
        );
    }
}
